/* Layout */
.headerlink {
  font-weight: 500;
}
.headerlink:hover {
  color: #000000;
}
.headerlink-active {
  color: #ffda7a;
}
.headerlink-active:hover {
  color: #ffda7a;
}
.page {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1280px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.ant-tabs-ink-bar {
  background: #63293a;
}
.card {
  width: 100%;
  padding: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.card:hover {
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
}
.section {
  margin: 40px;
}
.footerlink:hover {
  color: #ffffff;
}

/* Loader */
.spinner {
  animation: rotator 1.4s linear infinite;
}
@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}
@keyframes colors {
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1b9a59;
  }
  100% {
    stroke: #4285f4;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

/* Buttons */
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.button {
  margin: 40px;
}
#submit {
  color: #fff;
  background-color: #3fc060;
}
#locked-clicked {
  color: #fff;
  background-color: #200589;
}
#locked-unclicked {
  border: 1px solid #200589;
  color: #200589;
  background-color: #fff;
}
#self-unclicked {
  border: 1px solid #24a0ed;
  color: #24a0ed;
  background-color: #fff;
}
#self-clicked {
  color: #fff;
  background-color: #24a0ed;
}
#group-unclicked {
  border: 1px solid #ff00bf;
  color: #ff00bf;
  background-color: #fff;
}
#group-clicked {
  color: #fff;
  background-color: #ff00bf;
}

/* Misc */
.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}
.right {
  display: flex;
  flex-direction: row;
  justify-content: right;
  text-align: right;
}
.row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.fixed-height {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 150px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Ovo, sans-serif;
}
p,
b,
i,
li,
label {
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  line-height: 24px;
}
ul {
  list-style-type: none;
}
.highlight {
  font-weight: bold;
  color: #63293a;
}
.primary-link {
  color: #63293a;
  text-decoration: none;
}
.primary-link:hover {
  color: #63293a;
  text-decoration: none;
}
.secondary-link:hover {
  color: #ffffff;
  text-decoration: none;
}

/* Mobile */
@media screen and (max-width: 700px) {
  .page {
    padding: 10px;
  }
  .fixed-height {
    height: 300px;
  }
}
