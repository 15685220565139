.token-unclicked {
  border: 1px solid #63293a;
  color: #63293a;
  background-color: #fff;
}
.token-unclicked:hover {
  color: #63293a;
}
.token-clicked {
  color: #fff;
  background-color: #63293a;
}
.token-clicked:hover {
  color: #fff;
}
